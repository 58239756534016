import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Combos, combos } from '../combos';
import { factory } from '../commons/factory';
import { AuthGuest } from '../core/auth-guest/auth-guest.model';
import { AuthUser } from '../core/auth-user/auth-user.model';
import { HomeBanner } from '../core/home-banner/home-banner.model';
import { HomeHero } from '../core/home-hero/home-hero.model';
import { PlayCategory } from '../core/play-category/play-category.model';
import { faqs } from '../faqs';
import { SsrService } from './ssr.service';

export type StoreKey =
    '_'
  | 'combos'
  | 'helpCenterContent'
  | 'authGuest'
  | 'authUser'
  | 'homeHero'
  | 'homeBanner'
  | 'playCategories'
  | 'trailerVideoMuted'
  | 'newsletter'
  | 'subscribeStep'
  | 'deviceId'
  ;

export type EmitReason =
    'login'
  | 'logout'
  | 'addToMyList'
  | 'removeFromMyList'
  | 'checkoutSubscription'
  | 'checkoutPpv'
  | 'redeemGiftCard'
  ;

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  private _localStoragePrefix = 'app.';

  event: EventEmitter<{ concept: StoreKey, reason: EmitReason, is: (compareKey: StoreKey, compareReasons: EmitReason[]) => boolean }> = new EventEmitter();

  _: BehaviorSubject<any> = new BehaviorSubject(
    this._hasInStorage('_')
      ? this._getFromStorage('_').value
      : null
  );

  combos: BehaviorSubject<Combos> = new BehaviorSubject(
    this._hasInStorage('combos')
      ? this._getFromStorage('combos').value
      : combos
  );

  helpCenterContent: BehaviorSubject<any> = new BehaviorSubject(
    this._hasInStorage('helpCenterContent')
      ? this._getFromStorage('helpCenterContent').value
      : faqs
  );

  authGuest: BehaviorSubject<AuthGuest> = new BehaviorSubject(
    this._hasInStorage('authGuest')
      ? factory.create(this._getFromStorage('authGuest').value, AuthGuest)
      : null
  );

  authUser: BehaviorSubject<AuthUser> = new BehaviorSubject(
    this._hasInStorage('authUser')
      ? factory.create(this._getFromStorage('authUser').value, AuthUser)
      : null
  );

  homeHero: BehaviorSubject<HomeHero> = new BehaviorSubject(
    this._hasInStorage('homeHero')
      ? factory.create(this._getFromStorage('homeHero').value, HomeHero)
      : null
  );

  homeBanner: BehaviorSubject<HomeBanner> = new BehaviorSubject(
    this._hasInStorage('homeBanner')
      ? factory.create(this._getFromStorage('homeBanner').value, HomeBanner)
      : null
  );

  playCategories: BehaviorSubject<PlayCategory[]> = new BehaviorSubject(
    this._hasInStorage('playCategories')
      ? factory.createMany(this._getFromStorage('playCategories').value, PlayCategory)
      : null
  );

  trailerVideoMuted: BehaviorSubject<any> = new BehaviorSubject(
    this._hasInStorage('trailerVideoMuted')
      ? this._getFromStorage('trailerVideoMuted').value
      : null
  );

  newsletter: BehaviorSubject<any> = new BehaviorSubject(
    this._hasInStorage('newsletter')
      ? this._getFromStorage('newsletter').value
      : null
  );

  subscribeStep: BehaviorSubject<any> = new BehaviorSubject(
    this._hasInStorage('subscribeStep')
      ? this._getFromStorage('subscribeStep').value
      : null
  );

  deviceId: BehaviorSubject<any> = new BehaviorSubject(
    this._hasInStorage('deviceId')
      ? this._getFromStorage('deviceId').value
      : null
  );

  constructor(
    private _ssrS: SsrService,
  ) { }

  /* -------------------- */

  private _hasInStorage(key: StoreKey): boolean {
    const res = this._getFromStorage(key);

    if (res) {
      if (!res.expiresAt) {
        return true;
      }

      const now = new Date();
      const expiresAt = new Date(res.expires_at);

      if (now >= expiresAt) {
        this._removeFromStorage(key);
        return false;
      }

      return true;
    }

    return false;
  }

  private _getFromStorage(key: StoreKey): any {
    if (this._ssrS.isServer()) return;
    return JSON.parse(localStorage.getItem(`${this._localStoragePrefix}${key}`));
  }

  private _removeFromStorage(key: StoreKey): void {
    if (this._ssrS.isServer()) return;
    localStorage.removeItem(`${this._localStoragePrefix}${key}`);
  }

  /* -------------------- */

  emit(key: StoreKey, reason: EmitReason): void {
    this.event.emit({
      concept: key,
      reason: reason,
      is: (compareKey: StoreKey, compareReasons: EmitReason[]) => {
        return compareKey === key && compareReasons.includes(reason);
      }
    });
  }

  /* -------------------- */

  set(key: StoreKey, value: any, inLocalStorage: boolean = false, expiresIn: number = 0): void {
    if (this._ssrS.isBrowser() && inLocalStorage) {
      let now = new Date();
      now.setMinutes(now.getMinutes() + expiresIn);

      localStorage.setItem(`${this._localStoragePrefix}${key}`, JSON.stringify({
        value,
        expires_at: expiresIn ? now.toISOString() : null,
      }));
    }

    this[key].next(value);
  }

  update(key: StoreKey, value: any): void {
    if (this._hasInStorage(key)) {
      let data = this._getFromStorage(key);

      localStorage.setItem(`${this._localStoragePrefix}${key}`, JSON.stringify({
        value,
        expires_at: data.expires_at || null,
      }));
    }

    this[key].next(value);
  }

  remove(key: StoreKey): void {
    if (this._hasInStorage(key)) {
      this._removeFromStorage(key);
    }

    this[key].next(null);
  }
}
