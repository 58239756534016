import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import supportsWebP from 'supports-webp';
import { browserSupports } from '../browser-supports';
import { factory } from '../commons/factory';
import { redirect } from '../commons/redirect';
import { HomeBanner } from '../core/home-banner/home-banner.model';
import { HomeHero } from '../core/home-hero/home-hero.model';
import { PlayCategory } from '../core/play-category/play-category.model';
import { helper } from '../helper';
import { info } from '../info';
import { AuthGuestHttpService } from './http/auth-guest-http.service';
import { AuthUserHttpService } from './http/auth-user-http.service';
import { CombosHttpService } from './http/combos-http.service';
import { HomeHttpService } from './http/home-http.service';
import { homeHttpServiceMock } from './http/mocks/mocks';
import { PlayCategoryHttpService } from './http/play-category-http.service';
import { NavService } from './nav.service';
import { NewsletterService } from './newsletter.service';
import { SsrService } from './ssr.service';
import { StoreService } from './store.service';
import { SupportsWebpService } from './supports-webp.service';
import { LocationHttpService } from './http/location-http.service';

@Injectable({
  providedIn: 'root'
})
export class InitService {

  noRequest: boolean = false;

  messages = {
    error: 'services.init_service.messages.error',
  };

  langs = ['es-AR', 'es-MX'];
  lang = environment.lang_default;

  constructor(
    @Inject(DOCUMENT) private _dom: Document,
    private _translateS: TranslateService,
    private _storeS: StoreService,
    private _ssrS: SsrService,
    private _authGuestHttpS: AuthGuestHttpService,
    private _authUserHttpS: AuthUserHttpService,
    private _playCategoryHttpS: PlayCategoryHttpService,
    private _combosS: CombosHttpService,
    private _homeHttpS: HomeHttpService,
    private _supportsWebpS: SupportsWebpService,
    private _locationHttpS: LocationHttpService,
    private _injector: Injector,
  ) { }

  get _navS(): NavService {
    return this._injector.get(NavService);
  }

  get _newsletterS(): NewsletterService {
    return this._injector.get(NewsletterService);
  }

  /* -------------------- */

  resolveAuthGuest(): Promise<void> {
    if (this.noRequest) {
      return Promise.resolve();
    }

    return new Promise<void>(async (resolve, reject) => {
      if (!this._storeS.authGuest.value?.isLoggedIn()) {
        await this._authGuestHttpS.login().toPromise();
      }

      return resolve();
    });
  }

  /* -------------------- */

  resolveAuthUser(): Promise<void> {
    if (this.noRequest) {
      return Promise.resolve();
    }

    return new Promise<void>(async (resolve, reject) => {
      if (this._ssrS.isServer()) return resolve();

      if (this._storeS.authUser.value?.isLoggedIn()) {
        let error: boolean = false;

        const resGetProfile = await this._authUserHttpS.getProfile().toPromise().catch(() => error = true);
        const resGetSubscription = await this._authUserHttpS.getSubscription().toPromise().catch(() => error = true);

        if (!error) {
          this._storeS.authUser.value.user.set({
            ...resGetProfile.data,
            ...resGetSubscription.data
          });

          this._storeS.update('authUser', this._storeS.authUser.value);
        }
      }

      return resolve();
    });
  }

  resolveSubscriptionPlans(): Promise<void> {
    if (this.noRequest) {
      // return Promise.resolve();
    }

    return new Promise<void>(async (resolve, reject) => {
      const res = await this._combosS.getSubscriptionPlans().toPromise();
      const plan = res.data.find((plan: any) => ['plan mensual', 'suscripcion mensual'].includes((plan.name as string).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))) // Plan Mensual o Suscripción Mensual

      if (!plan) {
        this._navS.pages.NotAvailableRegionPage.nav();
        return resolve();
      }

      info.subscriptionPrice = `${plan.currency_symbol}${plan.amount}`;
      info.subscriptionPriceSubscribeBar = `${plan.currency_symbol} ${plan.amount}`;
      info.subscriptionDescription = plan.description;

      if (plan.currency_iso === 'ARS') {
        info.subscriptionPromoAmount = `${plan.currency_symbol}${Math.floor(parseInt(plan.amount) * info.subscriptionPromoOff)}`;
      } else {
        info.subscriptionPromoAmount = `${plan.currency_symbol}${(parseFloat(plan.amount) * info.subscriptionPromoOff)}`;
        info.subscriptionPromoAmount = info.subscriptionPromoAmount.slice(0, info.subscriptionPromoAmount.length - 1);
      }

      return resolve();
    });
  }

  resolveCombos(): Promise<void> {
    if (this.noRequest) {
      return Promise.resolve();
    }

    return new Promise<void>((resolve, reject) => {
      //

      return resolve();
    });
  }

  resolveHomeHero(): Promise<void> {
    if (this.noRequest) {
      return Promise.resolve();
    }

    return new Promise<void>(async (resolve, reject) => {
      const res = await this._homeHttpS.getHero().toPromise();

      if (!res[0]) {
        return reject();
      }

      const homeHero = factory.create(res[0], HomeHero);
      this._storeS.set('homeHero', homeHero);

      return resolve();
    });
  }

  resolveHomeBanner(): Promise<void> {
    if (this.noRequest) {
      return Promise.resolve();
    }

    this._storeS.set('homeBanner', null);
    return Promise.resolve();

    return new Promise<void>(async (resolve, reject) => {
      const res = await this._homeHttpS.getBanner().toPromise();

      if (!res[0]) {
        this._storeS.set('homeBanner', null);
        return resolve();
      }

      const homeBanner = factory.create(res[0], HomeBanner);
      this._storeS.set('homeBanner', homeBanner);

      return resolve();
    });
  }

  resolvePlayCategories(): Promise<void> {
    if (this.noRequest) {
      return Promise.resolve();
    }

    return new Promise<void>(async (resolve, reject) => {
      await this._playCategoryHttpS.get().toPromise();

      return resolve();
    });
  }

  resolveLocation(): Promise<void> {
    if (this.noRequest) {
      return Promise.resolve();
    }

    return new Promise<void>(async (resolve, reject) => {
      const res: any = await this._locationHttpS.get().toPromise();

      if (this.langs.includes(res.data?.country?.iso_code)) {
        await this._translateS.use(res.data.country.iso_code).toPromise();
      }

      return resolve();
    });
  }

  resolveDeviceId(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (!this._storeS.deviceId.value) {
        this._storeS.set('deviceId', helper.generateUUID(), true);
      }

      return resolve();
    });
  }

  /* -------------------- */

  async init(): Promise<void> {
    // return new Promise<void>((resolve, reject) => reject());

    if (this._ssrS.isBrowser()) {
      // if (['/suscribite', '/suscribite-inter', '/registro'].includes(window.location.pathname)) {
      //   this.noRequest = true;
      // }

      const redirectRes = redirect(window.location.pathname, window.location.search);

      if (redirectRes) {
        window.location.replace(redirectRes);
        return Promise.reject();
      }

      const storage = localStorage.getItem('storage');

      if (!storage || storage !== environment.storage) {
        sessionStorage.clear();

        localStorage.clear();
        localStorage.setItem('storage', environment.storage);
      }
    }

    /* -------------------- */

    this._translateS.setDefaultLang(environment.lang_default);
    await this._translateS.use(this.lang).toPromise();

    browserSupports.webp = await supportsWebP;

    this.messages.error = await this._translateS.get(this.messages.error).toPromise();

    helper.printTimestamp('InitService.init()', true);

    return new Promise<void>((resolve, reject) => {
      this.resolveAuthGuest().then(() => {
        Promise.all([
          this.resolveDeviceId(),
          this.resolveLocation(),
          this.resolveAuthUser(),
          this.resolveSubscriptionPlans(),
          this.resolveCombos(),
          this.resolveHomeHero(),
          this.resolveHomeBanner(),
          this.resolvePlayCategories(),
        ]).then(() => {
          this.finish();
          return resolve();
        }).catch((err) => {
          this.finish(true);
          return reject(this.messages.error);
        });
      }).catch(() => {
        this.finish(true);
        return reject(this.messages.error);
      });
    });
  }

  finish(withError?: boolean) {
    helper.printTimestamp('InitService.finish()');

    if (this._ssrS.isBrowser() && !withError) {
      this._dom.querySelector('body').classList.add('ready');

      this._newsletterS.init();
    }
  }
}
