import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService, TokenType } from './core/http.service';
import { StoreService } from '../store.service';
import { map } from 'rxjs/operators';
import { AuthUser } from 'src/app/core/auth-user/auth-user.model';
import * as _ from 'lodash';
import { AuthUserPlayHttpService } from './auth-user-play-http.service';
import { PlayCategoryHttpService } from './play-category-http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthUserHttpService {

  constructor(
    private _httpS: HttpService,
    private _httpClient: HttpClient,
    private _storeS: StoreService,
    private _authUserPlayHttpS: AuthUserPlayHttpService,
    private _playCategoryHttpS: PlayCategoryHttpService,
  ) { }

  async storeUser(value: any, remember_me: boolean) {
    this._storeS.set('authUser', new AuthUser(value), remember_me);

    const resGetSubscription = await this.getSubscription().toPromise();

    this._storeS.authUser.value.user.set(resGetSubscription.data);

    this._storeS.update('authUser', this._storeS.authUser.value);
    this._storeS.emit('authUser', 'login');

    await this._playCategoryHttpS.get().toPromise();
  }

  /* -------------------- */

  login(input: { email?: string, phone_1?: string, password: string, remember_me: boolean, deviceid?: string }) {
    const tokenType: TokenType = 'authGuestToken';

    input = {
      ...input,
      deviceid: this._storeS.deviceId?.value || '',
    }

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/elogin/authenticate`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType).pipe(
      map(async (res: any) => {
        await this.storeUser(res, input.remember_me);
        return res;
      })
    );
  }

  registerValidate(input: any) {
    const tokenType: TokenType = 'authGuestToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/users/validateUser/step1`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  /* -------------------- */

  getProfile() {
    const tokenType: TokenType = 'authUserToken';

    return this._httpS.send<any>(this._httpClient.get(this._httpS.getEndpoint(`/profile/getProfile`), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  getSubscription() {
    const tokenType: TokenType = 'authUserToken';

    return this._httpS.send<any>(this._httpClient.get(this._httpS.getEndpoint(`/subscriptions/getData`), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  getInvoices(params?: any) {
    const tokenType: TokenType = 'authUserToken';
    const paramsParsed = this._httpS.parseParams(params);

    return this._httpS.send<any>(this._httpClient.get(this._httpS.getEndpoint(`/users/getInvoices?${paramsParsed}`), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  /* -------------------- */

  profileUpdate(input: any) {
    const tokenType: TokenType = 'authUserToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/profile/updateUser`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  passwordUpdate(input: any) {
    const tokenType: TokenType = 'authUserToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/users/change-password`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  creditCardUpdate(input: any) {
    const tokenType: TokenType = 'authUserToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/profile/update-credit-card`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  linkDevice(input: any) {
    const tokenType: TokenType = 'authUserToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/profile/linkDevice`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  /* -------------------- */

  emailVerificationRequest(input: any) {
    const tokenType: TokenType = 'authGuestToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/users/verification-email/request`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }));
  }

  emailVerificationConfirm(hash: string, params?: any) {
    const tokenType: TokenType = 'authGuestToken';
    const paramsParsed = this._httpS.parseParams(params);

    return this._httpS.send<any>(this._httpClient.get(this._httpS.getEndpoint(`/users/verification-email/confirm/${hash}?${paramsParsed}`), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }));
  }

  emailChange(input: any) {
    const tokenType: TokenType = 'authUserToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/users/change-email`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }));
  }

  /* -------------------- */

  subscriptionCancelRequest(input: any) {
    const tokenType: TokenType = 'authUserToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/subscriptions/cancel-subscription/request`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }));
  }

  subscriptionCancel(input: any) {
    const tokenType: TokenType = 'authUserToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/subscriptions/cancel-subscription`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  unsubscriptionCancel() {
    const tokenType: TokenType = 'authUserToken';

    return this._httpS.send<any>(this._httpClient.get(this._httpS.getEndpoint(`/subscriptions/undo-cancel-subscription`), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }
}
