import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { ToastService } from '../../toast.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { StoreService } from '../../store.service';
import { helper } from 'src/app/helper';
import { isArray } from 'lodash';
import { CacheService } from '../../cache.service';

export type TokenType = 'authGuestToken' | 'authUserToken';

export interface ApiResponse {
  [key: string]: any,
}

export interface ApiErrorResponse {
  code: number | false,
  status: number,
  statusText: string,
  name: string,
  message: string,
  data: any,
  exception: string,
  type: string,
  errors: any,
  but?: any,
  trace?: any
  errorOn200?: boolean,
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  useMocks: boolean = environment.apiMock;

  constructor(
    public storeS: StoreService,
    private _toastS: ToastService,
    private _translateS: TranslateService,
    private _cacheS: CacheService,
  ) { }

  /* -------------------- */

  getEndpoint(endpoint: string): string {
    if (endpoint.startsWith('http')) {
      return endpoint;
    }

    if (endpoint.includes('cache:')) {
      endpoint = endpoint.replace('cache:', '');

      return environment.apiUrlCache + environment.apiSlug + endpoint;
    }

    return environment.apiUrl + environment.apiSlug + endpoint;
  }

  getHeaders(options?: any) {
    let headers = {
      'Accept': 'application/json',
      'Accept-Language': this._translateS.currentLang,
      'DeviceId': this.storeS.deviceId?.value || '',
    };

    if (options) {
      if (options.token) headers['Authorization'] = 'bearer ' + options.token;
    }

    return headers;
  }

  getToken(tokenType?: TokenType): string {
    let token: string;

    switch (tokenType) {
      case 'authGuestToken': token = _.get(this.storeS.authGuest.value, 'token', null); break;
      case 'authUserToken': token = _.get(this.storeS.authUser.value, 'token', null); break;
      default: break;
    }

    if (!token) token = _.get(this.storeS.authGuest.value, 'token', null);

    return token;
  }

  /* -------------------- */

  parseParams(params: any, prefix = null): string {
    let query = [];

    for (let p in params) {
      if (params.hasOwnProperty(p)) {
        let k = prefix ? prefix + '[' + p + ']' : p;
        let v = params[p];

        if (v !== null && typeof v === 'object') {
          query.push(this.parseParams(v, k));
        } else {
          query.push(k + '=' + v);
        }
      }
    }

    return query.join('&');
  }

  /* -------------------- */

  send<T>(httpClientRequest: Observable<T>, tokenType?: TokenType): Observable<T> {
    return httpClientRequest.pipe(
      map((res: any) => {
        if (res.success === false) {
          return this._parseErrorOn200(res);
        }

        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        const apiErrorResponse: ApiErrorResponse = this._parseError(err);

        if (tokenType === 'authUserToken') {
          if (apiErrorResponse.status === 401) {
            if (this.storeS.authUser.value?.isLoggedIn()) {
              this.storeS.authUser.value?.logout(this.storeS, this._cacheS);
            }
          }
        }

        return throwError(apiErrorResponse);
      }),
      finalize(() => {
        helper.printTimestamp('HttpService.send()');
      }),
    );
  }

  private _parseError(err: HttpErrorResponse): ApiErrorResponse {
    const defaultMessage: string = 'Ha ocurrido un error.';

    let apiErrorResponse: ApiErrorResponse = {
      code: 0,
      status: err.status,
      statusText: err.statusText,
      name: null,
      message: defaultMessage,
      data: err.message,
      exception: null,
      type: 'Unknown',
      errors: null,
    };

    if (err.error?.error && isArray(err.error.error)) {
      apiErrorResponse = {
        ...apiErrorResponse,
        ...err.error.error
      };

      delete apiErrorResponse.trace;
    }

    if (apiErrorResponse.status === 422) {
      apiErrorResponse.errors = err.error?.errors;
    }

    // if (apiErrorResponse.type === 'Unknown') {
    //   return apiErrorResponse;
    // }

    if (apiErrorResponse.code === 0) {
      switch (apiErrorResponse.status) {
        case 400: apiErrorResponse.message = 'Petición mal formada.'; break;
        case 401: apiErrorResponse.message = 'No autenticado.'; break;
        case 403: apiErrorResponse.message = 'Esta acción no está autorizada.'; break;
        case 404: apiErrorResponse.message = 'Recurso no encontrado.'; break;
        case 422: apiErrorResponse.message = 'Los datos proporcionados no son válidos.'; break;

        default: apiErrorResponse.message = defaultMessage; break;
      }
    }

    if (err.error?.message?.startsWith('[')) {
      let message = err.error?.message.split('] ');

      apiErrorResponse.type = message[0].substring(1);
      apiErrorResponse.message = message[1];
    }

    return apiErrorResponse;
  }

  private _parseErrorOn200(err: any): ApiErrorResponse {
    const defaultMessage: string = 'Ha ocurrido un error.';

    let apiErrorResponse: ApiErrorResponse = {
      code: err.inner_code || 0,
      status: err.code,
      statusText: null,
      name: null,
      message: defaultMessage,
      data: err.message,
      exception: null,
      type: 'Unknown',
      errors: null,
      errorOn200: true,
    };

    if (apiErrorResponse.status === 422) {
      apiErrorResponse.errors = err.message;
    }

    switch (apiErrorResponse.status) {
      case 400: apiErrorResponse.message = 'Petición mal formada.'; break;
      case 401: apiErrorResponse.message = 'No autenticado.'; break;
      case 403: apiErrorResponse.message = 'Esta acción no está autorizada.'; break;
      case 404: apiErrorResponse.message = 'Recurso no encontrado.'; break;
      case 422: apiErrorResponse.message = 'Los datos proporcionados no son válidos.'; break;

      default: apiErrorResponse.message = defaultMessage; break;
    }

    if (err.message?.scalar?.startsWith('[')) {
      let message = err.message?.scalar.split('] ');

      apiErrorResponse.type = message[0].substring(1);
      apiErrorResponse.message = message[1];
    }

    if (err.hasOwnProperty('geo_available') && !err.geo_available) {
      apiErrorResponse.message = 'Obra no disponible en tu región.';
    }

    return apiErrorResponse;
  }


}
